import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Box, Grid, IconButton, Paper, Tooltip, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { language } from '../../App';

import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import GitHubIcon from '@material-ui/icons/GitHub';
import ImageIcon from '@material-ui/icons/Image';

const useStyles = makeStyles({
  portfolioContent: {
    '@media (max-width: 768px)': {
      width: 'auto',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: 10
    },
    width: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: 10
  },
  titleContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  divider: {
    marginTop: 10,
    marginBottom: 10
  },
  contentRepo: {
    '@media (max-width: 768px)': {
      paddingLeft: 0
    },
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10
  },
  imageRepo: {
    '@media (max-width: 768px)': {
      width: 60,
      height: 60,
      borderRadius: 5,
      marginBottom: 10
    },
    width: 100,
    height: 100,
    borderRadius: 5,
    display: 'flex',
    alignSelf: 'center'
  },
  image: {
    width: '100%'
  },
  noImage: {
    '@media (max-width: 768px)': {
      width: 'auto',
      height: 60,
      borderRadius: 5
    },
    width: 'auto',
    height: 100,
    borderRadius: 5
  },
  contentButtons: {
    display: 'flex'
  }
});

const GET_REPOS = gql`
query {
  repositoryOwner(login: "DevRaphaGitHub") {
    repositories(first: 100, orderBy: {field: NAME, direction: ASC}){
      nodes{
        owner {
          login
        }
        name
        description
        usesCustomOpenGraphImage
        openGraphImageUrl
        isPrivate
        isFork
        url
        homepageUrl
        primaryLanguage{
          name
        }
      }
    }
  }
}
`;

const Repos = () => {
  const classes = useStyles();
  const { loading, data } = useQuery(GET_REPOS);

  if (loading) return <div>Loading...</div>;

  return (
    <>
      <div className={classes.titleContent}>
        <Typography variant="h5">{language.repos.repo}</Typography>
      </div>
      <Divider variant="middle" className={classes.divider} />
      <Grid container justify="flex-start" alignContent="flex-start" spacing={1}>
        {data.repositoryOwner.repositories.nodes.map((repo, index) => {
          if (repo.owner.login !== "DevRaphaGitHub" || repo.isFork === true) {
            return null;
          }
          return (
            <Grid key={index} item xs={6}>
              <Paper elevation={3} className={classes.portfolioContent}>
                <Box className={classes.imageRepo}>
                  {repo.usesCustomOpenGraphImage === false
                    ? <ImageIcon className={classes.noImage} />
                    : <img className={classes.image} src={repo.openGraphImageUrl} alt={repo.name} />}
                </Box>
                <Box className={classes.contentRepo}>
                  <Box>
                    <Typography variant="body2">{language.repos.name}: {repo.name}</Typography>
                    <Typography variant="body2">{language.repos.description}: {repo.description}</Typography>
                    <Typography variant="body2">{language.repos.language}: {repo.primaryLanguage.name}</Typography>
                    {repo.isPrivate === true
                      ? <Typography color="error" variant="body2">{language.repos.private}</Typography>
                      : ''}
                  </Box>
                  <Box className={classes.contentButtons}>
                    {repo.homepageUrl !== null && repo.homepageUrl !== ''
                      ? <Tooltip title="Open site">
                          <IconButton href={repo.homepageUrl} target="_blank">
                            <OpenInNewIcon />
                          </IconButton> 
                        </Tooltip> 
                      : ''}
                    {repo.isPrivate !== true
                      ? <Tooltip title="Open repository">
                          <IconButton href={repo.url} target="_blank">
                            <GitHubIcon />
                          </IconButton> 
                        </Tooltip> 
                      : ''}
                  </Box>
                </Box>
              </Paper>
            </Grid>
        )})}
      </Grid>
    </>
  );
}

export default Repos;