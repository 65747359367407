import React from 'react';
import { Grid, Paper, Typography, Divider, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { language } from '../../App';

const useStyles = makeStyles({
  titleContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  divider: {
    marginTop: 10,
    marginBottom: 10
  },
  skillBox: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    padding: 5
  },
  imgSkills: {
    width: 80,
    height: 80
  },
  imgSkillsMobile: {
    width: 50,
    height: 50
  }
});

const Skills = () => {
  const classes = useStyles();

  const mobile = useMediaQuery('(max-width: 768px)');

  const skills = require('./skills.json');
  skills.sort(function(a, b) {
    if (a.title > b.title) {
      return 1;
    }
    if (a.title < b.title) {
      return -1;
    }
    return 0;
  });

  return (
    <>
      <div className={classes.titleContent}>
        <Typography variant="h5">{language.skills}</Typography>
      </div>
      <Divider variant="middle" className={classes.divider} />
      <Grid container justify="center" alignContent="center" spacing={1}>
        {skills.map((skill, index) => {
          return (
            <Grid key={index} item xs={"auto"}>
              <Paper key={skill.title} elevation={3} className={classes.skillBox}>
                {mobile === false 
                  ? <img className={classes.imgSkills} src={skill.img} alt={skill.title} /> 
                  : <img className={classes.imgSkillsMobile} src={skill.img} alt={skill.title} />}
                <Typography variant="body2">{skill.title}</Typography>
              </Paper>
            </Grid>
        )})}
      </Grid>
    </>
  );
}

export default Skills;