import React from 'react';
import { Typography, Divider, Avatar, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import avatar from '../../assets/avatar.jfif';
// import cv from '../../docs/cv_raphael.pdf';
import { language } from '../../App';

const useStyles = makeStyles({
  titleContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  divider: {
    marginTop: 10,
    marginBottom: 10
  },
  avatar: {
    width: 150,
    height: 150,
    margin: 5
  },
  about: {
    marginLeft: 10
  },
  buttonCv: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10
  }
});

const Profile = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.titleContent}>
        <Avatar alt="Raphael Cunha" src={avatar} className={classes.avatar}/>
        <Typography variant="h5">{language.bio.about}</Typography>
      </div>
      <Divider variant="middle" className={classes.divider} />
      <Box className={classes.about}>
        <Typography variant="body1">{language.bio.linha1}</Typography><br/>
        <Typography variant="body1">{language.bio.linha2}</Typography><br/>
        <Typography variant="body1">{language.bio.linha3}</Typography>
        {/* <div className={classes.buttonCv}>
          <Button 
            href={cv} 
            download="CV Raphael" 
            variant="outlined"
            startIcon={<CloudDownloadIcon />}
          >
            Download CV
          </Button>
        </div> */}
      </Box>
    </>
  );
}

export default Profile;