import React, { useState, useCallback } from 'react';
import { ApolloProvider } from '@apollo/client';
import Particles from 'react-particles';
import { loadFull } from "tsparticles";

import {
  Paper,
  useScrollTrigger,
  Slide,
  AppBar,
  Toolbar,
  Tooltip,
  Typography,
  Button,
  IconButton,
  useMediaQuery,
  BottomNavigation,
  BottomNavigationAction,
  CssBaseline,
  Fab
} from '@material-ui/core';

import HomeIcon from '@material-ui/icons/Home';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import PersonIcon from '@material-ui/icons/Person';
import SchoolIcon from '@material-ui/icons/School';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { unstable_createMuiStrictModeTheme as createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';

import Repos from './components/Repos/Repos';
import Skills from './components/Skills/Skills';
import Profile from './components/Profile/Profile';
import BackToTop from './components/BackToTop/BackToTop';

import { client } from './config/client-graphql';

export let language = {};

const App = () => {
  const ptBR = require('../src/translate/portuguese.json');
  //const enUS = require('../src/translate/english.json');
  //const frCA = require('../src/translate/french.json');

  // switch (navigator.language) {
  //   case 'pt-BR': language = ptBR; break;
  //   case 'en-US': language = enUS; break;
  //   case 'en-CA': language = enUS; break;
  //   case 'fr-CA': language = frCA; break;
  //   case 'fr-FR': language = frCA; break;
  //   default: language = ptBR; break;
  // }

  language = ptBR;
  const mobile = useMediaQuery('(max-width: 768px)');

  const optionsDark = {
    background: {
        color: {
            value: "#000",
        },
    },
    fpsLimit: 120,
    particles: {
        color: {
            value: "#4fc3f7",
        },
        links: {
            color: "#4fc3f7",
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
        },
        collisions: {
            enable: true,
        },
        move: {
            directions: "none",
            enable: true,
            outModes: {
                default: "bounce",
            },
            random: false,
            speed: 3,
            straight: false,
        },
        number: {
            density: {
                enable: true,
                area: 800,
            },
            value: 80,
        },
        opacity: {
            value: 0.5,
        },
        shape: {
            type: "circle",
        },
        size: {
            value: { min: 1, max: 5 },
        },
    },
  };

  const optionsLight = {
    background: {
        color: {
            value: "#eee",
        },
    },
    fpsLimit: 120,
    particles: {
        color: {
            value: "#a0a0a0",
        },
        links: {
            color: "#a0a0a0",
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
        },
        collisions: {
            enable: true,
        },
        move: {
            directions: "none",
            enable: true,
            outModes: {
                default: "bounce",
            },
            random: false,
            speed: 3,
            straight: false,
        },
        number: {
            density: {
                enable: true,
                area: 800,
            },
            value: 80,
        },
        opacity: {
            value: 0.5,
        },
        shape: {
            type: "circle",
        },
        size: {
            value: { min: 1, max: 5 },
        },
    },
  };

  const particlesInit = useCallback(async engine => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async container => {
      //console.log(container);
  }, []);

  function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({ target: window ? window() : undefined });
  
    if (!mobile) {
      return (
        <div>
          {children}
        </div>
      );
    }
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  }

  // Dark mode
  const darkTheme = createMuiTheme({
    palette: {
      type: 'dark',
      background: {
        paper: '#212121'
      }
    },
    typography: {
      h3: {
        fontSize: '3rem',
        '@media (max-width: 768px)': {
          fontSize: '1.5rem',
        }
      },
      body2: {
        '@media (max-width: 768px)': {
          fontSize: '12px'
        }
      }
    },
    overrides: {
      MuiBottomNavigationAction: {
        root: {
          '&$selected': {
            color: '#4fc3f7'
          },
          fontWeight: 'bold'
        }
      }
    }
  });

  // Light mode
  const lightTheme = createMuiTheme({
    palette: {
      type: 'light',
    },
    typography: {
      h3: {
        fontSize: '3rem',
        '@media (max-width: 768px)': {
          fontSize: '1.5rem',
        }
      },
      body2: {
        '@media (max-width: 768px)': {
          fontSize: '12px'
        }
      }
    }
  });

  const [theme, setTheme] = useState(darkTheme);
  const toggleThemeLight = () => {
    setTheme(lightTheme);
  };
  const toggleThemeDark = () => {
    setTheme(darkTheme);
  };

  const [selected, setSelected] = useState('home');

  const handleChangePage = (event, newValue) => {
    setSelected(newValue);
  };

  const scrollIntoHome = (event) => {
    const anchorHome = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');
    anchorHome.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const scrollIntoAbout = (event) => {
    const anchorAbout = (event.target.ownerDocument || document).querySelector('#about');
    anchorAbout.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const scrollIntoSkills = (event) => {
    const anchorSkills = (event.target.ownerDocument || document).querySelector('#skills');
    anchorSkills.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const scrollIntoRepos = (event) => {
    const anchorRepos = (event.target.ownerDocument || document).querySelector('#repos');
    anchorRepos.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const useStyles = makeStyles((theme) => ({
    menu: {
      '& > *': {
        margin: theme.spacing(1),
      },
      width: '100vw',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    socialBar: {
      position: 'fixed',
      right: 0,
      margin: 5
    },
    mobileBar: {
      width: '100vw',
      position: 'fixed',
      bottom: 0,
      zIndex: 10
    },
    titleHome: {
      display: 'flex',
      justifyContent: 'center'
    },
    particles: {
      position: 'fixed',
      width: 'auto',
      height: 'auto',
      zIndex: -1
    },
    content: {
      height: '100vh'
    },
    contentBox: {
      display: 'flex',
      justifyContent: 'center'
    },
    paperContent: {
      width: '95vw',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginBottom: '10vh',
      padding: 10
    },
    titleContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    }
  }));
  const classes = useStyles();

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <div id="back-to-top-anchor"/>
        <HideOnScroll>
          <AppBar position="fixed" color="inherit">
            <Toolbar variant="dense">
              {mobile === false ?
              <Typography variant="h6" color="inherit" className={classes.menu}>
                <Button onClick={scrollIntoHome}>{language.toolbar.home}</Button>
                <Button onClick={scrollIntoRepos}>{language.toolbar.portfolio}</Button>
                <Button onClick={scrollIntoSkills}>{language.toolbar.skills}</Button>
                <Button onClick={scrollIntoAbout}>{language.toolbar.about}</Button>
              </Typography> : ''}
              <Typography edge="end" className={classes.socialBar}>
                <Tooltip title="Whatsapp">
                  <IconButton href="https://wa.me/5528999049701" target="_blank">
                    <WhatsAppIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="LinkedIn">
                  <IconButton href="https://www.linkedin.com/in/raphael-cunha-dutra" target="_blank">
                    <LinkedInIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="GitHub">
                  <IconButton href="https://github.com/DevRaphaGitHub" target="_blank">
                    <GitHubIcon />
                  </IconButton>
                </Tooltip>
                {theme.palette.type === 'dark' ?
                <Tooltip title={language.toolbar.theme}>
                  <IconButton onClick={toggleThemeLight}>
                    <Brightness7Icon />
                  </IconButton>
                </Tooltip> : ''}
                {theme.palette.type === 'light' ?
                <Tooltip title="Alternar tema claro/escuro">
                  <IconButton onClick={toggleThemeDark}>
                    <Brightness4Icon />
                  </IconButton>
                </Tooltip> : ''}
              </Typography>
            </Toolbar>
          </AppBar>
        </HideOnScroll>
        {mobile === true ?
        <BottomNavigation value={selected} onChange={handleChangePage} className={classes.mobileBar}>
          <BottomNavigationAction onClick={scrollIntoRepos} label={language.toolbar.portfolio} value="portfolio" icon={<BusinessCenterIcon />} />
          <BottomNavigationAction onClick={scrollIntoSkills} label={language.toolbar.skills} value="skills" icon={<SchoolIcon />} />
          <BottomNavigationAction onClick={scrollIntoAbout} label={language.toolbar.about} value="sobre" icon={<PersonIcon />} />
          <BottomNavigationAction onClick={scrollIntoHome} label={language.toolbar.home} value="home" icon={<HomeIcon />} />
        </BottomNavigation> : ''}
        <div className={classes.content}>
          {theme.palette.type === 'dark' ?
            <div className={classes.particles}>
              <Particles width="100vw" height="100vh" init={particlesInit} loaded={particlesLoaded} options={optionsDark}/>
            </div> : ''}
          {theme.palette.type === 'light' ?
            <div className={classes.particles}>
              <Particles width="100vw" height="100vh" init={particlesInit} loaded={particlesLoaded} options={optionsLight}/>
            </div> : ''}
          <Typography style={{paddingTop: '40vh'}} variant="h3" className={classes.titleHome}>
            Raphael Cunha
          </Typography>
          <Typography variant="h3" className={classes.titleHome}>
            {language.titleDev}
          </Typography>
          <div style={{marginTop: '50vh'}} className={classes.contentBox}>
            <div id="about">
              <Paper elevation={3} className={classes.paperContent}>
                <Profile />
              </Paper>
            </div>
          </div>
          <div className={classes.contentBox}>
            <div id="skills">
              <Paper elevation={3} className={classes.paperContent}>
                <Skills />
              </Paper>
            </div>
          </div>
          <div className={classes.contentBox}>
            <div id="repos">
              <Paper elevation={3} className={classes.paperContent}>
                <Repos />
              </Paper>
            </div>
          </div>
        </div>
        {mobile === false ?
        <BackToTop>
          <Tooltip title="Back to top">
            <Fab color="primary" size="large" aria-label="scroll back to top">
              <KeyboardArrowUpIcon />
            </Fab>
          </Tooltip>
        </BackToTop> : ''}
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;